import React, { useState } from 'react'
import styles from './SimpleSelectColor.module.scss'

const SimpleSelectColor = ({ variants = [], selected, chooseSelected }) => {
  return (
    <div>
      <div>
        <h5 className='mb-0'>Litur</h5>
        <p className='mb-3'>{selected.value}</p>
      </div>
      <form onSubmit={e => e.preventDefault()} className={styles.form}>
        {variants.map((item, i) => {
          const isSelected = selected.key === item.key
          return (
            <div key={i} className={isSelected ? styles.buttonContainer : styles.notSelected}>
              <div className={styles.roundedBox} style={{border: item.hex === '' ? '1px solid #000' : 'none'}}>
                <button
                  onClick={() => chooseSelected(item.key)}
                  key={i}
                  className={styles.colorButton}
                  style={{ backgroundColor: item.hex, height: '30px', width: '30px'}}
                >
                </button>
              </div>
            </div>
        )})}
      </form>
    </div>
  )
}

export default SimpleSelectColor
