import * as React from 'react'

// TODO: Move these to a global file to be shared across other components after Lerna has been setup
const breakpoints = {
  'xs': 0,
  'sm': 576,
  'md': 768,
  'lg': 992,
  'xm': 1045,
  'xl': 1200,
  'xxl': 1393,
  'logo-overflow': 167,
  'assistance': 1282
}

export const useResizer = bp => {
  if (typeof window === 'undefined') return

  const getInnerWidth = () => {
    return window.innerWidth
  }

  const [isMobile, setIsMobile] = React.useState(getInnerWidth() <= breakpoints[bp])
  const handleSizeChange = () => {
    return setIsMobile(window.innerWidth <= breakpoints[bp])
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleSizeChange)

    return () => {
      window.removeEventListener('resize', handleSizeChange)
    }
  }, [isMobile])

  return isMobile
}
