import React, { useState } from 'react'
import InputWithDropdown from '@cmp/site/InputWithDropdown'
import Button from '@cmp/site/Button'
import cn from 'classnames'

/**
 * variants: {key: string, value: string}[]
 * selected: {key: string, value: string}
 */

const SimpleSelect = ({ variants = [], selected, chooseSelected, className }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    <InputWithDropdown label={selected.value} className={className}>
      <form onSubmit={e => e.preventDefault()} className='filter--scrollable'>
        {variants.map((item, i) => (
          <button
            onClick={() => chooseSelected(item.key)}
            key={i}
            className={cn(
              { 'border-top': i !== 0 },
              'filter__item filter__item--hover-effect p-0 no-style d-flex justify-content-between align-items-center w-100'
            )}
          >
            <p className='mb-0 pl-1'>{item.value}</p>
          </button>
        ))}
      </form>
    </InputWithDropdown>
  )
}

export default SimpleSelect
