import React, { useState, useContext, useEffect, useCallback } from 'react'
import ReactMarkdown from 'react-markdown'
import { Helmet } from 'react-helmet'
import { MainLayout, Button, FavoriteBtn } from '@cmp/site/'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { GlobalDataProvider } from '@src/data/global-data'
import { Container, Row, Col } from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import cn from 'classnames'
import styles from './ProductPage.module.scss'
import AliceCarousel from 'react-alice-carousel'
import { MenuTypeContext } from '@root/src/context/MenuTypeContext'
import { FooterVisibilityContext } from '@root/src/context/FooterVisibilityContext'
import { useResizer } from '@src/hooks/useMobileDetection.js'
import { formatPriceString } from '@root/src/utils/index'
import { useCartState, useCartActions } from '@src/context/Cart'
import { useQuery } from '@apollo/client'
import { GET_PRODUCT_COUNT } from '@src/data/backend/queries'
import SimpleSelect from '@cmp/site/SimpleSelect'
import { ValidationMessage } from '../../site/index'
import { useCartVisibility } from '@root/src/context/CartVisibility'
import productFallbackImg from '@src/assets/logos/LH-logo-trans.png'
import SimpleSelectColor from '../../site/SimpleSelectColor/SimpleSelectColor'
import { GET_PRODUCT_GROUP } from '../../../data/backend/queries'
import ProductCarousel from '../../sections/ProductCarousel/ProductCarousel'

const ProductPage = ({ pageContext }) => {
  const {
    productGroupId,
    title,
    description,
    shortDescription,
    usage,
    ingredients,
    products,
    category,
    subcategory
  } = pageContext

  const { quantities } = useCartState()
  const { modifyCartVisibility, open: openCart } = useCartVisibility()
  const { addProductToCart, loading, error } = useCartActions({
    onAddedToCart: openCart
  })

  const isSnyrtivara = category.categoryId === 'SNYRTIVARA'
  const { t, language } = useLanguage()
  const { modifyVisibility } = useContext(FooterVisibilityContext)
  if(!products || products.length === 0 || !products[0]) return <></>
  const [selectedProduct, setSelectedProduct] = useState(products[0])

  const getInventory = useQuery(GET_PRODUCT_COUNT, {
    ssr: false,
    variables: {
      id: selectedProduct.productId
    }
  })
  const inventory = getInventory.data?.navproduct.inventoryCount
  const hasDiscount =
    selectedProduct.fullPrice !== selectedProduct.discountPrice

  // For now, we display the price until we know the inventory
  const buttonPriceLabel =
    inventory > 0 || !getInventory.data ? 'Setja í körfu' : 'Uppselt'

  const containerClass = cn(styles.container, {
    [styles.blackTheme]: isSnyrtivara
  })
  const infoSectionClasses = cn(
    'd-flex flex-column justify-content-center',
    styles.infoSection
  )

  useEffect(() => {
    modify(themeContext.header)
    modifyVisibility(themeContext.footer)
    setShowUserIcon(false)
    modifyCartVisibility(true)
  }, [])

  const carouselClasses = cn(styles.carousel)
  const buttonClass = cn('mb-1 mt-2')
  const { modify, setShowUserIcon } = useContext(MenuTypeContext)
  const themeContext = useResizer('md')
    ? { footer: false, header: 'primary' }
    : { footer: true, header: 'primary' }

  const onChangeProduct = useCallback(
    chosenProductId => {
      setSelectedProduct(products.find(p => p.productId === chosenProductId))
    },
    [products, setSelectedProduct]
  )

  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <Helmet>
        <title>
          {title} | {t`site title`}
        </title>
      </Helmet>
      <MainLayout isSnyrtivara={isSnyrtivara}>
        <Container className={containerClass}>
          <Row className='d-none d-md-flex'>
            <Col className='pb-3'>
              {/* Link to category overview */}
              <Link
                className={cn(styles.categoryLink, 'link')}
                to={`/${category.slug}`}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
                <h5 className='d-none d-md-inline'>{category.title}</h5>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col col={{ md: 7, lg: 6 }} className={carouselClasses}>
              {selectedProduct.discountPercent > 0 && (
                <span className={styles.discountLabel}>
                  -{selectedProduct.discountPercent}%
                </span>
              )}
              {selectedProduct.images.length > 1 ? (
                <ProductCarousel
                  images={selectedProduct.images}
                  title={title}
                />
              ) : selectedProduct.images.length === 1 ? (
                <img
                  width='570'
                  height='670'
                  alt={title}
                  key={selectedProduct.images[0].imageId}
                  className={styles.image}
                  src={selectedProduct.images[0].largeUrl}
                />
              ) : (
                <div className={styles.fallback}>
                  <img alt={title} src={productFallbackImg} />
                </div>
              )}
            </Col>
            <Col
              offset={{ md: 1 }}
              col={{ md: 4 }}
              className={infoSectionClasses}
            >
              {Boolean(category.title) && (
                <Link
                  className={cn(
                    styles.categoryLink,
                    'd-block d-md-none link d-flex align-items-center'
                  )}
                  to={`/${category.slug}`}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                  <h5 className='d-md-none d-inline mb-0'>{category.title}</h5>
                </Link>
              )}
              {Boolean(subcategory) && (
                <p
                  className={cn(
                    'eyebrow blue d-none d-md-block',
                    styles.brandName
                  )}
                >
                  {subcategory.title}
                </p>
              )}
              <p className='parag parag--big'>{title}</p>
              <div className='parag parag--small opacity--06'>
                <ReactMarkdown skipHtml>{shortDescription}</ReactMarkdown>
              </div>

              <div className='d-flex flex-column flex-lg-row pb-2 mb-1'>
                <div className='d-flex'>
                  {hasDiscount && (
                    <p className={cn('price-tag--striked', 'price-tag mr-2')}>
                      {formatPriceString(selectedProduct.fullPrice, true)}
                    </p>
                  )}
                  <p
                    className={cn('price-tag', {
                      'price-tag--discount': hasDiscount
                    })}
                  >
                    {formatPriceString(selectedProduct.discountPrice, true)}
                  </p>
                </div>
              </div>

              {products.length > 1 &&
                (products[0].seasonCode ? (
                  <SimpleSelectColor
                    selected={{
                      key: selectedProduct.productId,
                      value: selectedProduct.title
                    }}
                    chooseSelected={onChangeProduct}
                    variants={products.map(product => ({
                      key: product.productId,
                      value: product.title,
                      hex: product.seasonCode
                    }))}
                  />
                ) : (
                  <SimpleSelect
                    selected={{
                      key: selectedProduct.productId,
                      value: selectedProduct.title
                    }}
                    chooseSelected={onChangeProduct}
                    variants={products.map(product => ({
                      key: product.productId,
                      value: product.title
                    }))}
                  />
                ))}

              <div className={cn(buttonClass, 'd-flex w-100')}>
                {loading ? (
                  <Button
                    type='primary'
                    icon='loading'
                    className={styles.priceBtn}
                  />
                ) : (
                  <Button
                    disabled={inventory <= 0}
                    label={buttonPriceLabel}
                    icon='cart'
                    type='primary'
                    className={styles.priceBtn}
                    onClick={() => {
                      addProductToCart({
                        productGroupId,
                        productId: selectedProduct.productId,
                        quantity:
                          (quantities[selectedProduct.productId] || 0) + 1
                      })
                    }}
                  />
                )}
                <FavoriteBtn
                  id={productGroupId}
                  className={cn(styles.favoriteBtn, 'favorite-btn--large')}
                />
              </div>
              {error && <ValidationMessage message={error.message} />}
              <div className='pt-3 link-list link-list--bordered'>
                <Link to='/skilmalar'>
                  Afhending og skilmálar
                  <FontAwesomeIcon icon={faAngleRight} />
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col col={{ md: 9 }} className='pt-3'>
              <TextInfo title='Vöruupplýsingar' body={description} />
              <TextInfo title='Notkun' body={usage} />
              <TextInfo title='Innihaldslýsing' body={ingredients} />
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </GlobalDataProvider>
  )
}

const TextInfo = ({ title, body }) => {
  if (!body) return null

  return (
    <>
      <h5 className='parag--medium-bold blue pt-1'>{title}</h5>
      <div className='parag parag--small opacity--06'>
        <ReactMarkdown skipHtml>{body}</ReactMarkdown>
      </div>
    </>
  )
}

export default ProductPage
